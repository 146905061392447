@charset "utf-8";
@import "~bulma";
@import "~bulma-pageloader";
@import "~mapbox-gl/dist/mapbox-gl.css";

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  overflow: hidden;
}

#main {
  height: 100%;

  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.8;

    .map-loader {
      background-color: black;
      opacity: 0.8;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 100;
    }

    .map-loader:after {
      @include loader;
      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
    }

    cp-menu {
      display: none;
    }
  }
}

#map {
  height: 100%;
  width: 100%;
}

.marker {
  background-image: url('images/location-marker.png');
  background-size: 50px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  image-rendering: pixelated;
  background-repeat: no-repeat;
}

@for $i from 1 through 151 {
  .marker-pokemon-#{$i} {
    background-image: url('images/pokemon/animated/back/#{$i}.gif');
    background-size: contain;

    &.special {
      background-image: url('images/pokemon/animated/shiny/back/#{$i}.gif');
    }
  }

  .popup-image-#{$i} {
    background-image: url('images/pokemon/animated/#{$i}.gif');
    background-size: contain;
    width: 50px;
    height: 50px;
    cursor: pointer;
    image-rendering: pixelated;
    background-repeat: no-repeat;

    &.special {
      background-image: url('images/pokemon/animated/shiny/#{$i}.gif');
    }
  }
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

cp-menu {
  position: fixed;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 10px;

  .pokeball {
    background: transparent no-repeat url('images/poke-ball.png');
    background-size: 50px 50px;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
  }
}
